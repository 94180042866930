.chat-input-container {
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-top: 1px solid #e8e8e8;
  /* position: fixed; */
  flex-direction: row;
  /* bottom: 0; */
  /* z-index: 100; */
}

.chat-input {
  border-radius: 20px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  border: 1px solid #d9d9d9 !important;
  transition: all 0.3s ease !important;
}

.chat-input:hover {
  border-color: #40a9ff !important;
}

.chat-input:focus {
  border-color: #1890ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}

.chat-input:disabled {
  background-color: #f5f5f5 !important;
  cursor: not-allowed !important;
}

.chat-input::placeholder {
  color: #bfbfbf !important;
}

/* Style cho nút Submit */
.chat-input-container .ant-btn {
  border-radius: 20px !important;
  height: 40px !important;
  padding: 0 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: all 0.3s ease !important;
}

.chat-input-container .ant-btn:hover {
  /* transform: translateY(-1px); */
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.2);
}

.chat-input-container .ant-btn:active {
  transform: translateY(0);
}

.chat-input-container .ant-btn:disabled {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: #bfbfbf !important;
  cursor: not-allowed !important;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .chat-input-container {
    padding: 12px;
  }

  .chat-input {
    font-size: 13px !important;
  }

  .chat-input-container .ant-btn {
    height: 36px !important;
    padding: 0 16px !important;
  }
}

@media screen and (max-width: 480px) {
  .chat-input-container {
    padding: 8px;
  }

  .chat-input {
    font-size: 12px !important;
  }

  .chat-input-container .ant-btn {
    height: 32px !important;
    padding: 0 12px !important;
  }
}
