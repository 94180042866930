@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

/* Base styles */
.history-container {
  width: 90%;
  height: 800px;
  background: transparent;
  border-radius: 8px;
  margin-left: 80px;
  margin-top: 40px;
}

.history-title {
  color: white !important;
  margin-bottom: 40px;
  font-weight: 500;
  -webkit-background-clip: text;
  background-clip: text;
  font-family: 'Plus Jakarta Sans' !important;
  font-size: calc(1rem + 1vw); /* Kích thước font sẽ co giãn theo màn hình */
}

.conversation-content h3 {
  margin: 0;
  font-size: calc(0.7rem + 0.5vw); /* Kích thước font co giãn */
  color: white;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  max-width: 800px;
}

.conversation-content p {
  margin: 5px 0 0;
  font-size: calc(0.7rem + 0.1vw); /* Kích thước font co giãn */
  color: #b4b4b4;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}

.conversation-list {
  width: 100%;
  list-style: none;
  padding: 0;
  max-height: 800px;
  overflow-y: auto;
}

.conversation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #191919;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.conversation-actions {
  display: flex;
  gap: 5px;
}

.conversation-actions button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: opacity 0.3s;
}

.conversation-actions .open-btn {
  background: #007bff;
  color: white;
}

.conversation-actions .open-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.conversation-actions .delete-btn {
  background: #dc3545;
  color: white;
}

.conversation-actions .delete-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.conversation-actions button:hover:not(:disabled) {
  opacity: 0.8;
}

.history-empty {
  text-align: center;
  font-size: 14px;
  color: #777;
}

/* Responsive Design */

/* Màn hình lớn (desktop, > 1200px) */
@media screen and (max-width: 1200px) {
  .history-container {
    width: 90%;
    margin-left: 40px;
    height: 700px;
  }

  .conversation-list {
    max-height: 700px;
  }

  .conversation-content h3 {
    max-width: 600px;
  }

  .conversation-content p {
    max-width: 150px;
  }
}

/* Màn hình trung bình (tablet, 768px - 1200px) */
@media screen and (max-width: 768px) {
  .history-container {
    width: 95%;
    margin-left: 20px;
    margin-top: 30px;
    height: 600px;
  }

  .history-title {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .conversation-list {
    max-height: 600px;
  }

  .conversation-item {
    padding: 8px;
  }

  .conversation-content h3 {
    font-size: 15px;
    max-width: 400px;
  }

  .conversation-content p {
    font-size: 13px;
    max-width: 120px;
  }

  .conversation-actions button {
    padding: 4px 8px;
    font-size: 12px;
  }
}

/* Màn hình nhỏ (mobile, 480px - 768px) */
@media screen and (max-width: 480px) {
  .history-container {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    height: calc(100vh - 100px);
  }

  .history-title {
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .conversation-list {
    max-height: calc(100vh - 150px);
  }

  .conversation-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 6px;
  }

  .conversation-content h3 {
    font-size: 14px;
    max-width: 100%;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  }

  .conversation-content p {
    font-size: 12px;
    max-width: 100%;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  }

  .conversation-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }

  .conversation-actions button {
    padding: 3px 6px;
    font-size: 11px;
  }
}

/* Màn hình rất nhỏ (dưới 360px) */
@media screen and (max-width: 360px) {
  .history-container {
    margin-top: 10px;
    height: calc(100vh - 80px);
  }

  .history-title {
    font-size: 18px;
    margin-bottom: 15px;
    margin-left: 5px;
  }

  .conversation-list {
    max-height: calc(100vh - 120px);
  }

  .conversation-item {
    padding: 5px;
    gap: 8px;
  }

  .conversation-content h3 {
    font-size: 13px;
  }

  .conversation-content p {
    font-size: 11px;
  }

  .conversation-actions button {
    padding: 2px 5px;
    font-size: 10px;
  }
}