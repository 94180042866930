

.chat-rag-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100vh; 
  width: 100%;
  /* margin-top: 30px;  */
  font-family: Arial, sans-serif;
}

.chatbox {
  max-width: 100%;
  flex-grow: 1 ;
  max-height: 800px;
  flex: 1; 
  overflow: hidden;
  padding: 20px;
  background-color: #f0f2f5; 
  border-radius: 10px;
  margin-left: 80px;
  margin-right: 40px;
  margin-bottom: 40px;

}

.user-message {
  width: 30%;
  margin-left: 100px;
  align-self: flex-end;
  background-color: #007AFF;
  color: white;
  /* text-align: right; */
}

.bot-message {
  align-self: flex-start;
  background-color: #E9ECEF;
  color: #212529;
}

.chatbox .ant-bubble-list {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
}

.code-block {
  border-radius: 10px;
  overflow-x: auto;
}


.chatbox .ant-bubble-list .ant-bubble-content {
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: pre-wrap; 
  max-width: 100%;
}


.date_time {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  text-align: right;
}

.chatbox-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}



.custom-code-block {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
  overflow-x: auto;
  white-space: pre-wrap;
}

.custom-code-block pre {
  margin: 0;
}

.custom-code-block code {
  background: transparent;
}


.chat-input-container {
  padding: 20px;
  background-color: #ffffff;
  border-top: 1px solid #e8e8e8;
  flex-direction: column;
  position: sticky;
  justify-content: end;
}


.prompts-wrapper {
  transition: opacity 0.3s ease;
}

.prompts-wrapper.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.prompts-wrapper.visible {
  opacity: 1;
  margin-bottom: 20px;
}


.input {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-inline: 20px;
}


.chat-input {
  width: 500px;
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #d9d9d9;
  /* border-radius: 4px; */
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
}

.chat-input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.2);
}

.chat-input::placeholder {
  color: #bfbfbf;
}


.send-button {
  padding: 10px 20px;
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button:hover {
  background-color: #40a9ff;
}

.send-button:active {
  background-color: #096dd9;
}


@media (max-width: 800px) {
  .chat-rag-container {
    width: 100%;
    height: 100%;
  }

  .chatbot-container{
    width: 100%;
    height: 100%;
  }
  .chatbox {
    width: 95%;
    margin-right: 10px;
    padding: 10px;
    margin-left: 0px;
    margin-bottom:0px;
    border-radius: 0px;
  }

  .chat-input-container {
    padding: 10px;
    border-radius: 0px;
  }

  .send-button {
    padding: 8px 15px;
  }
  .dropdown-button-container{
    margin-left: 0px;
  }
}




