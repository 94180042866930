@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.settings-wrapper {
    max-width: 1200px;
    margin-left: 80px;
    margin-top: 20px;
    font-family:'Plus Jakarta Sans' !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .settings-header {
    color: white !important;
    margin-bottom: 40px;
    font-weight: 700;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: 'Plus Jakarta Sans' !important;
  }
  
  .delete-section {
    background: #191919;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: 100%;
  }
  
  .delete-section:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .delete-section p {
    font-size: 1.5rem;
    color: #fff;
    margin: 0 0 10px 0;
    font-weight: 600;
  }
  
  .delete-section small {
    color: #b6b8b8;
    font-size: 0.9rem;
    margin-bottom: 20px;
    display: block;
  }
  
  .collection-list {
    margin: 20px 0;
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;
    width: 100%;
  }
  
  .collection-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .collection-list::-webkit-scrollbar-track {
    background: #c9c7c7;
    border-radius: 4px;
  }
  
  .collection-list::-webkit-scrollbar-thumb {
    background: #3498db;
    border-radius: 4px;
  }
  
  .collection-list::-webkit-scrollbar-thumb:hover {
    background: #2980b9;
  }
  
  .collection-item {
    background: #f8f9fa;
    padding: 12px 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  }
  
  .collection-item:hover {
    background: #e9ecef;
    transform: translateX(5px);
  }
  
  .collection-item .ant-checkbox-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    color: #2c3e50;
    font-size: 1rem;
  }
  
  .collection-item .ant-checkbox + span {
    padding-left: 10px;
  }
  
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid #3498db;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #3498db;
    border-color: #3498db;
  }
  
  .ant-btn-primary {
    background: #e74c3c;
    border: none;
    padding: 10px 25px;
    font-size: 1.1rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(231, 76, 60, 0.3);
    align-self: flex-start;
  }
  
  .ant-btn-primary:hover {
    background: #c0392b;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(231, 76, 60, 0.4);
  }
  
  .ant-btn-primary:focus {
    background: #e74c3c;
    outline: none;
  }
  
  @media (max-width: 800px) {
  
    .settings-header {
      font-size: 2rem;
    }
  
    .delete-section {
      margin-right: 40px;
    }
  
    .delete-section p {
      font-size: 1.3rem;
    }
  
    .ant-btn-primary {
      width: 100%; 
      padding: 12px 20px;
    }
  }