@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.sidebar-container {
    height: 100%;
    border-radius: 20px;
}

.sidebar {
    background-color: #171717;
    color: white;
    height: 100vh;
    padding: 16px;
    transition: all 0.3s ease-in-out;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.sidebar.open {
    width: 14rem;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.sidebar.closed {
    width: 3rem;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.toggle-btn {
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
}

.toggle-btn:hover {
    background-color: #4a5568;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    gap: 12px;
}

.menu-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.menu-item:hover {
    background-color: #4a5568;
    color: azure;
}

.menu-label {
    font-size: 14px;
    font-weight: 500;
}

.welcome {
    color: white;
}
