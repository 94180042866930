@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

    body, html {
        overflow: hidden;
      }

    .chatbot-container {
        display: flex;
        width: 100%;
        height: 100vh;
        font-family: 'Plus Jakarta Sans';
        border-radius: 30px;
        overflow: hidden;
        padding-bottom: 20px;
    }
    .scroll_content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 80vh;
        padding: 20px;
        margin-right: 40px;
        margin-left: 40px;
        border-radius: 20px;
    }
    
    .chat-input-container {
        display: flex;
        justify-content: center;
        position:absolute;
        bottom: 20px;
        /* left: 10%; */
        width: 100%;
        background: white;
        border-radius: 50px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        /* padding: 10px 10px; */
    }

    .main {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

   
    .title {
        height: 10%;
        gap: 30px;
        background-color: transparent;
        color: white;
        display: flex;
        justify-content:flex-start;
        align-items:center;
        font-size: 30px ;
        font-weight: bold;
        border-radius: 10px;
        margin-left: 80px;
        font-family: 'Plus Jakarta Sans';
        text-overflow: ellipsis;
    }


    .title-button{
        font-size: 30px;
        transition: all 0.3s ease;
    }

    .title-button .anticon {
        font-size: 30px;
        transition: all 0.3s ease;
    }

    .chatbox {
        flex-grow: 1; 
        min-height: 60vh; 
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
    
   
    .message {
        display: inline-block;
        width: 80%;
        height: auto;
        padding: 15px;
        align-items: center;
        align-self: center;
        border-radius: 12px;
        margin-bottom: 15px;
        word-wrap: break-word;
        font-size: 16px;
        font-family: 'Plus Jakarta Sans';
    }

    .date_time{
        font-size: 12px;
        font-family: 'Plus Jakarta Sans';
        align-self:flex-end;
        justify-content: flex-start;
    }

   
    .user {
        width: 80%;
        display: inline-block;
        background-color: transparent;
        color: black;
        background: #f1f1f1;
        text-align: end;
        align-items: center;
        word-wrap: break-word;
        justify-content: center;
    }

   
    .bot {
        width: 70%;
        display: inline-block; 
        max-width: 80%;
        padding: 10px;
        background: #f1f1f1;
        border-radius: 10px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: pre-wrap; 
        overflow: ellipsis;
        max-width: 100%; 
    }

    .message bot{
        background-color: #4CAF50;
    }
   
    .chat-input {
        flex: 1;
        padding: 10px;
        border: none;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        border: 1px solid #ccc;
        background-color: #fff;
        color: black;
        margin-right: 10px;
        border-radius: 20px;
        display: block;
        overflow: visible;
    }
    

   
    .send-button {
        border: none;
        border-radius: 20px;
        background-color: #7B5AFF;
        color: white;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
       
    }


    .prompts-container {
        margin-bottom: 330px;
    }
    
    .sender-container {
        margin-bottom: 30px;
    }
    

    @media (max-width: 1200px) {
        .scroll_content {
            height: 75vh;
            margin-right: 30px;
            margin-left: 30px;
        }

        .chatbox {
            min-height: 55vh;
        }

        .message {
            width: 75%;
            font-size: 15px;
        }

        .chat-input-container {
            width: 70%;
        }
    }

    @media (max-width: 800px) {
        .scroll_content {
            height: 70vh;
            margin-right: 20px;
            margin-left: 20px;
            padding: 15px;
        }

        .chatbox {
            min-height: 50vh;
        }

        .message {
            width: 85%;
            padding: 12px;
            font-size: 14px;
        }

        .title {
            font-size: 25px;
            margin-left: 30px;
        }

        .title-button .anticon {
            font-size: 20px;
        }

        .chat-input-container {
            width: 90%;
            padding: 8px 15px;
        }
    }

    @media (max-width: 480px) {
        .scroll_content {
            height: 65vh;
            margin-right: 15px;
            margin-left: 15px;
            /* padding: 10px; */
        }

        .chatbox {
            min-height: 45vh;
        }

        .message {
            width: 90%;
            padding: 10px;
            font-size: 13px;
        }

        .title {
            font-size: 20px;
            margin-left: 20px;
        }

        .title-button .anticon {
            font-size: 20px;
        }

        .chat-input-container {
            width: 95%;
            padding: 6px 12px;
        }
    }

    @media (max-width: 320px) {
        .scroll_content {
            height: 60vh;
            margin-right: 10px;
            margin-left: 10px;
            padding: 8px;
        }

        .chatbox {
            min-height: 40vh;
        }

        .message {
            width: 95%;
            padding: 8px;
            font-size: 12px;
        }

        .title {
            font-size: 18px;
            margin-left: 15px;
        }

        .title-button .anticon {
            font-size: 16px;
        }

        .chat-input-container {
            width: 98%;
            padding: 5px 10px;
        }
    }

    /* Responsive cho landscape mode */
    @media (max-height: 500px) and (orientation: landscape) {
        .scroll_content {
            height: 85vh;
        }

        .chatbox {
            min-height: 70vh;
        }

        .message {
            padding: 8px;
            margin-bottom: 8px;
        }

        .chat-input-container {
            bottom: 10px;
            padding: 5px 15px;
        }
    }
    
    
