
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.upload-container {
    width: 100%;
    margin-left: 80px;
    margin-top: 10px;
    display: flex;
    font-family: 'Plus Jakarta Sans';
    flex-direction: column;
    align-items: flex-start;
  }
  
  .upload-title {
    font-size: 2.5rem;
    color: white !important;
    margin-bottom: 40px;
    font-weight: 700;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: 'Plus Jakarta Sans';
  }
  
  .upload-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .upload-card {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: all 0.3s ease;
  }
  
  .upload-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .upload-card .ant-typography {
    color: #2c3e50;
    font-weight: 600;
  }
  
  .upload-input {
    margin-bottom: 15px;
    border-radius: 6px;
    padding: 8px 12px;
  }
  
  .upload-button {
    background: #3498db;
    border: none;
    border-radius: 6px;
    height: 40px;
    font-size: 1.1rem;
    transition: all 0.3s ease;
  }
  
  .upload-button:hover {
    background: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
  }
  
  .upload-button:focus {
    background: #3498db;
    outline: none;
  }
  
  @media (max-width: 800px) {
    .upload-container {
      width: 100%;
      padding: 30px 15px;
    }
  
    .upload-title {
      font-size: 2rem;
    }
  
    .upload-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .upload-card {
      width: 100%;
    }
  
    .upload-button {
      width: 100%;
    }
  }