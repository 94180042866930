body, html {
  overflow: hidden; 
  margin: 0; 
  padding: 0;
  height: 100%; 
}

.parent {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.div1 {
  transition: all 0.3s ease;
  width: 250px;
  /* min-width: 0; */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #1a1a1a;
  z-index: 999;
  transform: translateX(0);
}

.div1.closed {
  transform: translateX(-100%);
}

.div2 {
  height: 100%;
  flex: 1;
  background-color: #2c2c2c;
  overflow: hidden;
  position: relative;
}

.div2::after {
  display: none;
}

.div2.partial {
  opacity: 0.8;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.1s ease;
}

.div2.full {
  position: relative;
  background-color: #2c2c2c;
}

.toggle-button {
  position: sticky;
  top: 10px;
  transition: left 0.3s ease, right 0.3s ease;
  background-color: transparent;
  z-index: 1000;
  padding: 10px 20px;
  cursor: pointer;
}

.bot {
  display: none;
}

.chatbot-iframe {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: 600px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  transition: all 0.3s ease;
  pointer-events: auto;
  background: white;
  position: fixed;
  top: auto;
  left: auto;
  right: 20px;
  bottom: 20px;
}

.iframe-container {
  display: none;
}

.iframe-container iframe.bot {
  width: 100%;
  height: 420px;
  border: none;
  opacity: 0;
  transition: opacity 0.9s ease-in-out;
}

.iframe-container iframe.bot[src] {
  opacity: 1;
}

.iframe-container:has(iframe) {
  height: 420px;
}

.menu-toggle-btn {
  position: fixed;
  z-index: 1000;
  background: transparent;
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.menu-toggle-btn .anticon {
  font-size: 30px;
}
